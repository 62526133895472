<template>
    <div>
        <div class="header_holder text-center ma">
            <router-link to="/users" class="left_btn">
                <v-icon small right>fas fa-users</v-icon>
            </router-link>
            <div class="rounder_top  mb-2">
                <div class="card_header ubuntu_b primer">
                    {{ (user.id)?'Promjena':'Dodavanje' }} korisnika
                </div>
            </div>
        </div>
        <div class="order_holder primer rounder pa-6 px-6">
            <div class="header">
                <form @submit.prevent="submit" :id="form_id">
                    <div class="form_inner">
                        <div class="input_holder">
                            <v-text-field
                                v-model="title"
                                :error-messages="titleErrors"
                                label="Ime zaposlenika"
                                required
                                v-eager
                                solo
                                @input="$v.title.$touch()"
                                @blur="$v.title.$touch()"
                            ></v-text-field>
                        </div>
                        <div class="input_holder border_top">
                            <v-text-field
                                v-model="email"
                                :error-messages="emailErrors"
                                label="Email"
                                required
                                v-eager
                                type="email"
                                solo
                                @input="$v.email.$touch()"
                                @blur="$v.email.$touch()"
                            ></v-text-field>
                        </div>
                        <div class="input_holder border_top">
                            <v-text-field
                                v-model="username"
                                :error-messages="usernameErrors"
                                label="korisničko ime"
                                required
                                v-eager
                                autocomplete="false"
                                solo
                                @input="$v.username.$touch()"
                                @blur="$v.username.$touch()"
                            ></v-text-field>
                        </div>
                        <div class="input_holder border_top">
                            <v-text-field
                                v-model="password"
                                :error-messages="passwordErrors"
                                label="lozinka"
                                v-eager
                                solo
                                type="password"
                                autocomplete="false"
                                @input="$v.password.$touch()"
                                @blur="$v.password.$touch()"
                            ></v-text-field>
                        </div>
                        <div class="input_holder border_top">
                            <v-text-field
                                v-model="password_rep"
                                :error-messages="password_repErrors"
                                label="ponovi lozinku"
                                v-eager
                                solo
                                type="password"
                                @input="$v.password_rep.$touch()"
                                @blur="$v.password_rep.$touch()"
                            ></v-text-field>
                        </div>
                        <div class="input_holder border_top">
                            <v-select
                                v-model="user_poslovnice"
                                :items="poslovnice"
                                item-text="title"
                                item-value="id"
                                label="Poslovnice"
                                multiple
                                chips
                                dense
                                solo
                            ></v-select>
                        </div>
                        <v-row v-if="!user.id" class="mt-6">
                            <v-col cols="12" class="text-left" @click="changeCredentials('narucivanje')">
                                <v-icon small class="mr-3">fas fa-{{(credentials.narucivanje)?'check':'times'}}</v-icon> Naručivanje
                            </v-col>
                            <v-col cols="12" class="text-left" @click="changeCredentials('reklamacije')">
                                <v-icon small class="mr-3">fas fa-{{(credentials.reklamacije)?'check':'times'}}</v-icon> Reklamacije
                            </v-col>
                            <v-col cols="12" class="text-left" @click="changeCredentials('useri')">
                                <v-icon small class="mr-3">fas fa-{{(credentials.useri)?'check':'times'}}</v-icon> Korisnici
                            </v-col>
                            <v-col cols="12" class="text-left" @click="changeCredentials('kosarice')">
                                <v-icon small class="mr-3">fas fa-{{(credentials.kosarice)?'check':'times'}}</v-icon> Košarice
                            </v-col>
                        </v-row>
                        <v-col cols="12" class="text-left" v-if="!user.id">
                            <v-switch v-model="status" class="ma-4" label="Aktivan" @click="changeStatus()"></v-switch>
                        </v-col>
                        <v-btn class="rounder submit_btn ubuntu_b" color="secondary" type="submit" :disabled="submitStatus === 'PENDING'">
                            {{ (user.id)?'Spremi izmjene':'Dodaj korisnika' }} 
                        </v-btn>
                    </div>
                    <div class="text-center pt-2">
                        <p class="typo__p" v-if="submitStatus === 'OK'">Sve je u redu</p>
                        <p class="typo__p error-txt" v-if="submitStatus === 'ERROR'">Niste pravilno popunili formu</p>
                        <p class="typo__p" v-if="submitStatus === 'PENDING'">Šaljem...</p>
                        <p class="typo__p error-txt" v-if="submitError !== ''">{{ submitError }}</p>
                    </div>
                </form>
                
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { helpers, email, required, maxLength, minLength, alphaNum, sameAs } from 'vuelidate/lib/validators'
const alpha_space_dash = helpers.regex('alpha_space_dash', /^[a-zA-ZĆ-ćČ-čĐ-đŠ-šŽ-ž\-_\s]*$/)
export default {
    data() {
        return {
            form_id: (this.$route.params.id)?'edit_user':'add_user',
            title: '',
            username: '', 
            password: '',
            password_rep: '',
            email: '',
            credentials: {
                narucivanje: 0,
                useri: 0,
                kosarice: 0,
                reklamacije: 0,
            },
            status:1,
            user_poslovnice: [],
            form_enabled: false,   
        }
    },
    props: ['id'],
    computed: {
        ...mapGetters({
            user_id: 'user/userId',
            user_by_id: 'users/user_by_id',
            poslovnice: 'users/getPoslovnice',
            submitStatus: 'forms/submitStatus',
            submitError: 'forms/submitError',
            userCredentials: 'user/getCredentials',
        }),
        user () {
            let user = this.user_by_id(this.$route.params.id)
            return user
        },
        user_poslovniceErrors () {
            const errors = []
            if (!this.$v.user_poslovnice.$dirty) return errors
            return errors
        },
        titleErrors () {
            const errors = []
            if (!this.$v.title.$dirty) return errors
            !this.$v.title.maxLength && errors.push('Maksimum 30 znakova')
            !this.$v.title.minLength && errors.push('Minimum dva znaka')
            !this.$v.title.alpha_space_dash && errors.push('dozvoljeni su samo slova, crtice i razmaci')
            !this.$v.title.required && errors.push('Ime zaposlenika je obavezno')
            return errors
        },
        usernameErrors () {
            const errors = []
            if (!this.$v.username.$dirty) return errors
            !this.$v.username.maxLength && errors.push('Maksimum 30 karaktera')
            !this.$v.username.minLength && errors.push('Minimum 4 karaktera za korisničko ime')
            !this.$v.username.alphaNum && errors.push('Dozvoljeni su samo slova i brojke')
            !this.$v.username.required && errors.push('Korisničko ime je obavezno')
            return errors
        },
        passwordErrors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            return errors
        },
        password_repErrors () {
            const errors = []
            if (!this.$v.password_rep.$dirty) return errors
            !this.$v.password_rep.sameAsPassword && errors.push("Ponovljena lozinka mora da se poklapa sa poljem lozinka")
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.required && errors.push('Email je obavezno polje')
            !this.$v.email.email && errors.push('Neispravan Email')
            return errors
        },
    },
    mixins: [validationMixin],
    validations: {
        user_poslovnice:{
        },
        email: {
            email,
            required,
        },
        title: {
            required,
            alpha_space_dash,
            minLength: minLength(2),
            maxLength: maxLength(30),
        },
        username: {
            required,
            alphaNum,
            minLength: minLength(4),
            maxLength: maxLength(30),
        },
        password: {
        },
        password_rep: {
            sameAsPassword: sameAs('password')
        }
    },
    methods: {
        changeStatus(){
            this.status = (this.status==1)?0:1
        },
        changeCredentials(name){
            this.credentials[name] = (this.credentials[name]==1)?0:1 
        },
        submit () {
            var formPass = false
            this.$v.$touch()
            formPass = this.$v.$invalid
            if (formPass) {
                this.$store.dispatch('forms/changeSubmitStatus','ERROR')
            } else {
                this.$store.dispatch('forms/changeSubmitStatus','PENDING')
                let formData = {
                    title: this.title,
                    username: this.username,
                    email: this.email,
                    credentials: this.credentials,
                    poslovnice: this.user_poslovnice,
                    status: this.status,
                    user_id: this.user_id //ovo je user koji ga pravi
                }
                if(this.password!==''){
                    formData.password = this.password
                }
                if(this.$route.params.id!=0){
                    formData.id = this.$route.params.id
                }
                this.$store.dispatch('forms/submitForm', { form: this.form_id, data: formData })
            }
        },
        back(){
            this.$router.go(-1)
        }
    },
    mounted(){
        if(this.$route.params.id>0){
            this.title = this.user.title
            this.email = this.user.email
            this.username = this.user.username
            this.user_poslovnice = this.user.poslovnice
            this.status = this.user.status
            this.credentials = this.user.credentials
        }
    },
    destroyed(){
        this.$store.dispatch('forms/changeSubmitStatus',null)
        this.$store.dispatch('forms/changeSubmitError',null)
    },
    beforeCreate(){
        if(this.$store.getters['user/getCredentials'].users == 0) this.$router.push({ name: 'not_auth' })
    }
}
</script>
<style scoped>
    .order_holder{
        box-shadow:0 2px 3px rgba(0,0,0,.07);
        margin-top:20px;
        padding:7px;
    }
    .order_sum {
        float: left !important;
        margin: 10px 90px 10px 10px;
        font-size: 1.1rem;
        font-weight: 500;
    }
    .order_dates{
        background-image:url('/img/strela.png');
        background-position: center center;
        background-repeat: none;
        background-size: 40px;
    }
    .router_right{
        float:right;
        margin-top:10px;
    }
    .order_header{
        width:100%;
        height:60px;
        min-height:60px !important;
        line-height: 60px;
        position:relative;
        padding:10px 10px 10px 60px !important;
        border-bottom:1px solid rgba(0,0,0,.03);
    }
    .product_holder, .package_holder, .bottom_order{
        position:relative;
        width:100%;
        min-height:50px;
        line-height:20px;
        padding:10px 10px 10px 10px;
        border-bottom:1px solid rgb(226, 226, 226);
    }
    .package_holder .product_holder{
        border:none;
        padding:0;
        color:rgb(143, 143, 143);
    }
    .bottom_order{
        height:50px;
        padding-top:15px;
        border:none;
        padding:0 !important;
        border-top:1px solid #000;
    }
    .product_img{
        position:absolute;
        left:0px;
        top:5px;
        width:70px;
        height:70px;
    }
    .product_title{
        min-height:20px;
        line-height:20px;
        position:relative;
    }
    .product_title span{
        position: absolute;
        right: 0;
        text-align: right;
        display: block;
        top: 0;
    }
    .blur{
        position:relative;
    }
    .blur::after{
        content:"";
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        background: rgba(255,255,255,.5);
    }
    .gray_cycle{
        position:absolute;
        left:18px;
        top:18px;
        width:24px;
        height:24px;
        border-radius:50%;
        background:#f7f7f7;
    }
    .item_icon, .bottom_order_icon{
        position:absolute;
        left:4px;
        top:4px;
        width:16px;
        height:16px;
    }
    .bottom_order_icon{
        left:24px;
        top:21px;
        width:24px;
        height:24px;
    }
    .top{
        bottom:unset;
        top:10px;
    }
</style>>