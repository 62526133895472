<template>
    <v-container>
        <v-row dense>
            <v-col cols="12">
                <usersEditComponent />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import UsersEditComponent from '../components/UsersEditComponent.vue'
export default {
    data() {
        return {
        }
    },
    components: { UsersEditComponent },
}
</script>
